/* eslint-disable indent */
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'common-confirmation-modal',
  templateUrl: './common-confirmation-modal.html',
  styleUrls: ['./common-confirmation-modal.scss']
})

export class CommonConfirmationModal {
  @Input() hasCloseIcon: boolean;
  @Input() isCenteredModal: boolean;
  @Input() closeIconText: string;
  @Input() modalIcon: string;
  @Input() topLabel: string;
  @Input() confirmationParagraphOne: string;
  @Input() confirmationParagraphTwo: string;
  @Input() buttonText: string;
  @Input() buttonAction: string;
  @Input() supportLinkText: string;
  @Input() supportLinkAction: string;

  constructor(
    private readonly modalCtrl: ModalController,
  ) {}

  emitBtnAction(): void {
    this.modalCtrl.dismiss(this.buttonAction);
  }

  emitInlineBtnAction(action: string): void {
    this.modalCtrl.dismiss(action);
  }

  emitSupportAction(): void {
    this.modalCtrl.dismiss(this.supportLinkAction);
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
