/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable indent */
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'biometric-modal',
  templateUrl: './biometric-modal.component.html',
  styleUrls: ['./biometric-modal.component.scss']
})
export class BiometricModalComponent {
  @Input() hasCloseIcon: boolean = false;
  @Input() topInlineButtons: any;
  @Input() textLineOne: any;
  @Input() textLineTwo: any;
  @Input() buttonText: string;
  @Input() buttonAction: string;
  @Input() supportLinkText: string;
  @Input() supportLinkAction: string;

  constructor(
    private readonly modalCtrl: ModalController,
  ) {
    // console.log('authbtns', this.topInlineButtons);
  }

  emitBtnAction(action: string): void {
    this.modalCtrl.dismiss(action);
  }

  closeModal(): void {
    this.modalCtrl.dismiss();
  }
}
