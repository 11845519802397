import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AddState, RemoveState, SetDefaultStates } from './api-call.actions';
import { ApiCallStateModel } from './api-call.model';
import { Injectable } from '@angular/core';

/**
 * Api Call State
 */
@Injectable()
@State({
  name: 'apiCallState',
  defaults: {
    contains: {},
  },
})
export class ApiCallState {
  /**
   * Get states
   *
   * @param state
   */
  @Selector()
  static getStates(state: ApiCallStateModel) {
    return state.contains;
  }

  /**
   * Get state
   *
   * @param state
   */
  @Selector()
  static getState(state: ApiCallStateModel) {
    return (payload: string) => state.contains[payload] ? state.contains[payload] : null;
  }

  /**
   * Add dynamic state
   *
   * @param param0
   * @param param1
   */
  @Action(AddState)
  add({ getState, patchState }: StateContext<ApiCallStateModel>, { payload }: AddState) {
    const state = JSON.parse(JSON.stringify(getState()));
    // delete state.contains[payload.name]
    if (state.contains && state.contains[payload.name]) {
      state.contains[payload.name] = payload.value;
    } else {
      state.contains = { ...state.contains, [payload.name]: payload.value };
    }

    patchState({
      contains: { ...state.contains },
    });
  }


  /**
   * Remove dynamic state
   *
   * @param param0
   * @param param1
   */
  @Action(RemoveState)
  remove({ getState, patchState }: StateContext<ApiCallStateModel>, { payload }: RemoveState) {
    const state = Object.assign({}, getState());
    delete state.contains[payload];
    patchState({
      contains: { ...state.contains },
    });
  }

  /**
   * Set dynamic state
   * @param param0
   */
  @Action(SetDefaultStates)
  set({ patchState }: StateContext<ApiCallStateModel>) {
    patchState({
      contains: {},
    });
  }
}
