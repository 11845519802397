import { CommonModule } from '@angular/common';
import { SplashComponent } from './splash.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [SplashComponent],
  exports: [SplashComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
})
export class SplashComponentModule { }
