/* eslint-disable indent */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  /**
   * This service is essentially a collection of setters/getters so that
   * many elements that the app needss for its flows can be handled internally
   * without resorting to localStorage
   */

  private appData: any;
  private countriesList: any;
  private CCAAsList: any;
  private provincesList: any;
  private availableIAs: any[] = [];

  constructor() { }

  // - Data pertaining to the app configuration
  setAppData(data: any) {
    this.appData = data;
  }

  getAppData() {
    return this.appData;
  }

  // - Data pertaining to geographical locations
  setCountriesList(data: any) {
    this.countriesList = data;
  }

  getCountriesList() {
    return this.countriesList;
  }

  setCCAAsList(data: any) {
    this.CCAAsList = data;
  }

  getCCAAsList() {
    return this.CCAAsList;
  }

  setProvincesList(data: any) {
    this.provincesList = data;
  }

  getProvincesList() {
    return this.provincesList;
  }

  // - Data related to the available IAs for the user - //
  setAvailableIAs(ias: Array<any>) {
    this.availableIAs = ias;
  }

  getIAsList() {
    return this.availableIAs;
  }
}
