/* eslint-disable indent */
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { Drivers } from '@ionic/storage';
import { ImageLoaderComponentModule } from 'src/app/components/image-loader/image-loader.module';
import { IonicConfig } from '@ionic/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { ModalsModule } from './modals/modals.module';
import { NgModule, isDevMode } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { RouteReuseStrategy } from '@angular/router';
import { ServicesModule } from './services/services.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { SplashComponentModule } from './components/splash/splash.module';
import { ServiceWorkerModule } from '@angular/service-worker';

/**
 * Create translate loader
 *
 * Pluralization @link {https://github.com/lephyrus/ngx-translate-messageformat-compiler}
 *
 * @param http
 */
export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, './assets/i18n/', '.json');

/**
 * Defines an IonicConfig variable to manage the app config.
 *
 * For more info {@link https://ionicframework.com/docs/utilities/config}
 */
const config: IonicConfig = {
  backButtonIcon: 'assets/img/arrow_back.svg',
  backButtonText: '',
  scrollAssist: true,
  scrollPadding: false,
  swipeBackEnabled: true,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(config),
    AppRoutingModule,
    HttpClientModule,
    ImageLoaderComponentModule,
    ModalsModule,
    //  NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    IonicStorageModule.forRoot({
      // eslint-disable-next-line no-underscore-dangle
      driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage],
      name: environment.dbName,
    }),
    ServicesModule,
    SplashComponentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   multi: true,
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorInterceptor,
    // },
    {
      multi: true,
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: { locales: ['es'] },
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
