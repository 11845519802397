import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { ApiCallState } from './store/api-call.state';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

// Store
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

/**
 * Configuration
 */
const configuration = new Configuration();

@NgModule({
  declarations: [],
  providers: [ApiCallService],
  imports: [
    NgxsModule.forRoot([ApiCallState],
      { developmentMode: !configuration.production },
    ),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: configuration.production,
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: configuration.production,
    }),
  ],
})
export class ApiCallModule {
  public static forRoot(
    configurationFactory: () => Configuration,
  ): ModuleWithProviders<ApiCallModule> {
    return {
      ngModule: ApiCallModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  /**
   * Constructor
   *
   * @param parentModule
   * @param http
   */
  constructor(
    @Optional() @SkipSelf() parentModule: ApiCallModule,
    @Optional() http: HttpClient,
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.',
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
