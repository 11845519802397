import { ChatIA } from './chat-ia';
import { User } from 'src/app/core/interfaces/user';

export type UserType = 'patient' | 'professional';

export type BioAuthSelection = 'fingerprint' | 'facial' | 'iris';

/**
 * Storage Key enum
 */
export enum StorageKey {
  accessToken = 'accessToken',
  language = 'language',
  userData = 'userData',
  userType = 'userType',
  firstLogin = 'firstLogin',
  firstAccess = 'firstAccess',
  userAcceptsBiometry = 'userAcceptsBiometry',
  biometryModalShown = 'biometryModalShown',
  chosenBiometry = 'chosenBiometry',
  availableIAs = 'availableIAs',
  availableCountries = 'availableCountries',
  availableCCAAs = 'availableCCAAs',
  availableProvinces = 'availableProvinces',
  availableSecurityQuestions = 'availableSecurityQuestions',
  areas = 'areas',
  positions = 'positions',
  professionalProfiles = 'professionalProfiles'
}

/**
 * App Skel Storage interface
 */
export interface AppSkelStorage {
  /**
   * Defines a string variable to manage the authentication token
   */
  [StorageKey.accessToken]: string;

  /**
   * Defines a string variable to manage the app language
   */
  [StorageKey.language]: string;

  /**
   * Defines a User variable to manage the user data
   */
  [StorageKey.userData]: User;

  /**
   * Defines a variable to detect the type of user (patient or medical professional)
   */
  [StorageKey.userType]: UserType;

  /**
   * Defines a boolean variable to assess if the user has logged in at least once
   */
  [StorageKey.firstLogin]: boolean;

  /**
   * Defines a boolean variable to determine if it's the first time the user opened the app AND accepted the disclaimer
   */
  [StorageKey.firstAccess]: boolean;

  /**
   * Defines a boolean variable to check whether the user has opted in to biometric authentication
   */
  [StorageKey.userAcceptsBiometry]: boolean;

  [StorageKey.biometryModalShown]: boolean;

  /**
   * Defines a string variable
   */
  [StorageKey.chosenBiometry]: BioAuthSelection;

  /**
   * Defines an array to store the available chat IAs.
   */
  [StorageKey.availableIAs]: ChatIA[];

  /**
   * Defines an array to store the available countries for registering a user.
   */
  [StorageKey.availableCountries]: any[];

  [StorageKey.availableCCAAs]: any[];
  /**
   * Defines an array to store the available provinces for registering a user.
   */
  [StorageKey.availableProvinces]: any[];

  /**
   * Defines an array to store the available security questions for registering a user.
   */
  [StorageKey.availableSecurityQuestions]: any[];

  [StorageKey.areas]: any[];
  [StorageKey.positions]: any[];

  [StorageKey.professionalProfiles]: any[];

  // Defines a boolean variable to assess if the user has logged in before
  [StorageKey.firstAccess]: boolean;

}
