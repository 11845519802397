import { Component, HostListener, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/services/settings/settings.service';

/**
 * Image Loader Component
 */
@Component({
  selector: 'app-splash',
  styleUrls: ['./splash.component.scss'],
  templateUrl: './splash.component.html',
})

export class SplashComponent implements OnInit {


  constructor(
    private settingsService: SettingsService,
  ) { }
  public screenHeight: string = window.innerHeight.toString() + 'px';
  public screenWidth: number = window.innerWidth;
  public splashImage;
  public maxScreenWidth: number = environment.maxScreenWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenWidth();
  }
  ngOnInit(): void {

    this.settingsService.getSplashImage().then((data) => {
      this.splashImage = data
    }).catch((error) => { console.log(error) })
  }
  updateScreenWidth(): void {
    this.screenWidth = window.innerWidth;
  }
}

