/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageKey } from 'src/app/core/interfaces/storage';
import { StorageService } from 'src/app/services/storage/storage.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';

@HostListener('scroll', ['$event'])

@Component({
  selector: 'use-disclaimer-modal',
  templateUrl: './use-disclaimer-modal.html',
  styleUrls: ['./use-disclaimer-modal.scss']
})

export class UseDisclaimerModal implements AfterViewInit {
  @ViewChild('scrollContainer') scrollContainer;
  @Input() modalHeader: string;
  @Input() legalText: string;
  @Input() canSkipScroll: boolean;
  public isScrollComplete: boolean = false;
  public isContainerScrollable: boolean;
  public sponsorsImages: string;
  public disclaimerTitle: string
  public disclaimerText: string


  constructor(
    private readonly modalCtrl: ModalController,
    private storage: StorageService,
    private settingsService: SettingsService,
    private translate: TranslateService
  ) {
  }

  ngAfterViewInit(): void {
    this.isContainerScrollable = this.scrollContainer.nativeElement.scrollHeight > this.scrollContainer.nativeElement.offsetHeight;
    const currentLanguage = this.translate.currentLang + '_' + this.translate.currentLang.toUpperCase();
    this.settingsService.getSettings().then((settings) => {
      this.disclaimerTitle = settings.app.disclaimerTitleliteral[currentLanguage]
      this.disclaimerText = settings.app.disclaimerliteral[currentLanguage]
    });
  }

  async dismiss(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
      this.isScrollComplete = true;
    }
  }

  acceptTerms(): void {
    this.storage.set(StorageKey.firstAccess, false);
    this.modalCtrl.dismiss();
  }

}
