import { AddState, SetDefaultStates } from './store/api-call.actions';
import { HttpClient, HttpHeaders, HttpParameterCodec } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { ApiCallState } from './store/api-call.state';
import { Configuration } from './configuration';
import { CustomHttpParameterCodec } from './encoder';
import { DataCall } from './model/data-call';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngxs/store';
import { throwError } from 'rxjs';

/**
 * Api Call Service
 */
@Injectable({
  providedIn: 'root',
// eslint-disable-next-line indent
})
export class ApiCallService {
  /**
   * Defines a string variable to manage the base path
   */
  //protected basePath = process.env.BASE_URL;
  protected basePath = '';

  /**
   * Defines a Configuration variable to manage the configuration
   */
  public configuration = new Configuration();

  /**
   * Defines a HttpHeaders variable to manage the default headers
   */
  public defaultHeaders = new HttpHeaders();

  /**
   * Defines a HttpParameterCodec variable to manage the encoder
   */
  public encoder: HttpParameterCodec;

  /**
   * Constructor
   *
   * @param httpClient
   * @param configuration
   * @param store
   */
  constructor(
    protected readonly httpClient: HttpClient,
    @Optional() configuration: Configuration,
    private readonly store: Store,
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      this.configuration.basePath = this.basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * Create call
   *
   * @param dataCall - Data call
   * @param options - (Optional) options
   */
  public createCall(dataCall: DataCall, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
    // Create Default Header
    let headers = this.defaultHeaders;

    // Check url config
    this.configuration.basePath = this.configuration.basePath
      ? this.configuration.basePath
      : this.basePath;

    // authentication (bearer) required
    if (this.configuration.accessToken || dataCall.accessToken) {
      const accessToken = this.configuration.accessToken || dataCall.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;

    if (httpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [
        'application/json',
      ];
      httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    let responseType: 'text' | 'json' = 'json';
    if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
      responseType = 'text';
    }


    // Choose http Call
    let params = '';
    if (dataCall.params) {
      dataCall.params.forEach((param) => {
        params = params + `/${encodeURIComponent(String(param))}`;
      });
    }
    const typesCall = {
      get: this.httpClient.get(
        `${this.configuration.basePath}${dataCall.path}${params}`,
        {
          params: dataCall.queryParams,
          headers,
        },
      ),
      put: this.httpClient.put(
        `${this.configuration.basePath}${dataCall.path}`,
        dataCall.body,
        {
          params: dataCall.queryParams,
          headers,
        },
      ),
      post: this.httpClient.post(
        `${this.configuration.basePath}${dataCall.path}`,
        dataCall.body,
        {
          params: dataCall.queryParams,
          headers,
        },
      ),
      patch: this.httpClient.patch(
        `${this.configuration.basePath}${dataCall.path}`,
        dataCall.body,
        {
          params: dataCall.queryParams,
          headers,
        },
      ),
      delete: this.httpClient.delete(
        `${this.configuration.basePath}${dataCall.path}${params}`,
        {
          params: dataCall.queryParams,
          headers,
        },
      ),
    };
    return typesCall[dataCall.type];
  }

  /**
   * Generate call
   *
   * @param dataCall - Data call
   */
  generateCall(dataCall: DataCall): Observable<any> {
    return this.createCall(dataCall).pipe(
      map((res) => {
        if (dataCall.storeName) {
          this.setStore(dataCall.storeName, res.data);
        }
        return res.data;
      }),
      catchError(this.handleError),
    );
  }

  /**
   * Set store
   *
   * @param storeName - Store name
   * @param data - Data to store
   */
  setStore(storeName: string, data: any): void {
    this.store.dispatch(
      new AddState({
        name: storeName,
        value: data,
      }),
    );
  }

  /**
   * Clear Api Store
   */
  clearApiStore() {
    this.store.dispatch(new SetDefaultStates());
  }

  /**
   * Get data stored
   *
   * @param storeName - Store name
   */
  getDataStore(storeName: string): Observable<any> {
    return this.store
      .select(ApiCallState.getState)
      .pipe(map((fn) => fn(storeName)));
  }

  /**
   * Handle error function
   *
   * @param error - Error
   */
  public handleError = (error: Response): Observable<any> => throwError(error);
}
