/* eslint-disable indent */
import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiCallService } from '../api-call';
import { DataCall } from '../api-call';
import { StorageKey } from 'src/app/core/interfaces/storage';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  @Output() messageReceivedEvent: EventEmitter<any> = new EventEmitter<any>();
  private selectedIA: any;

  constructor(
    private apiCallService: ApiCallService,
    private storage: StorageService,
  ) { }

  setSelectedIA(ia: any) {
    this.selectedIA = ia;
  }

  getSelectedIA() {
    return this.selectedIA;
  }

  async createConversation(iaData: any) {
    const token = await this.storage.get(StorageKey.accessToken);
    console.log(token)
    const dataCall: DataCall = {
      type: 'post',
      path: '/chat/createConversation',
      accessToken: token,
      body: iaData,
    };
    try {
      const req = await this.apiCallService.generateCall(dataCall).toPromise();
      return req.conversation;
    } catch (error) {
      console.error(error);
    }
  }

  async closeConversation(iaData: any) {
    const token = await this.storage.get(StorageKey.accessToken);
    const dataCall: DataCall = {
      type: 'post',
      path: '/chat/closeConversation',
      accessToken: token,
      body: iaData,
    };
    try {
      const req = await this.apiCallService.generateCall(dataCall).toPromise();
      return req.conversation;
    } catch (error) {
      console.error(error);
    }
  }

  async rateResponse(messageData: any) {
    const token = await this.storage.get(StorageKey.accessToken);
    const dataCall: DataCall = {
      type: 'post',
      path: '/chat/markMessageAsCorrectOrIncorrect',
      accessToken: token,
      body: messageData,
    };
    try {
      const req = await this.apiCallService.generateCall(dataCall).toPromise();
      return req.response;
    } catch (error) {
      console.error(error);
    }
  }

  sendNewMessage(token: string, messageData: any): void {
    console.log('message received', messageData);
    try {
      const dataCall: DataCall = {
        type: 'post',
        path: '/chat/sendMessage',
        accessToken: token,
        body: {
          IATypeId: 1,
          message: messageData,
        },
      };
      this.apiCallService.generateCall(dataCall);
    } catch (error) {
      console.error(error);
    }
  }


}
