/**
 * Backend error interface
 */
export interface BackendError {
  /**
   * Defines a string variable to manage the error field
   */
  error: string;

  /**
   * Defines a BackendErrorCode variable to manage the error_code field
   */
  error_code: BackendErrorCode;
}

// eslint-disable-next-line no-shadow
export enum BackendErrorCode {
  'GENERIC' = 'GENERIC',
}
