import { ApiObjectStateModel } from './api-call.model';

/**
 * Add State class
 */
export class AddState {
  /**
   * Defines a string variable to manage the type field
   */
  static readonly type = '[States] Add';

  /**
   * Constructor
   *
   * @param payload
   */
  constructor(
    public payload: ApiObjectStateModel,
  ) {}
}

/**
 * Remove State class
 */
export class RemoveState {
  /**
   * Defines a string variable to manage the type field
   */
  static readonly type = '[States] Remove';

  /**
   * Constructor
   *
   * @param payload
   */
  constructor(
    public payload: string,
  ) {}
}

/**
 * Set Default States class
 */
export class SetDefaultStates {
  /**
   * Defines a string variable to manage the type field
   */
  static readonly type = '[States] Set Default State';
}
