/* eslint-disable indent */
import { AlertController, IonRouterOutlet, Platform } from '@ionic/angular';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { StorageKey, UserType } from 'src/app/core/interfaces/storage';
import { App } from '@capacitor/app';
import { AuthService } from './services/auth/auth.service';
import { ChatService } from './services/chat/chat.service';
import { DataService } from './services/data/data.service';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import { StorageService } from 'src/app/services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/services/i18n/translation.service';
import { SettingsService } from './services/settings/settings.service';
import { ToastController } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { environment } from 'src/environments/environment';

/**
 * App Component
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

  /**
   * Defines an IonRouterOutlet variable to manage the router outlet
   */
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  /**
   * Constructor
   *
   * @param platform
   * @param storageService
   * @param translationService
   */
  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    private chatService: ChatService,
    private dataService: DataService,
    private router: Router,
    private readonly platform: Platform,
    private readonly storageService: StorageService,
    private translate: TranslateService,
    private readonly translationService: TranslationService,
    private settingService: SettingsService,
    private toastCtrl: ToastController,
  ) {
    this.setupLanguage();
    this.initStorage();
    this.initializeApp();
  }

  public showSplash = true;
  public isIphone: boolean = false;
  public screenHeight: string = window.innerHeight.toString() + 'px';
  public screenWidth: number = window.innerWidth;
  public maxScreenWidth: number = environment.maxScreenWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScreenWidth();
    this.updateScreenHeight();
  }
  updateScreenWidth(): void {
    this.screenWidth = window.innerWidth;
  }
  async updateScreenHeight() {
    const deviceInfo = await Device.getInfo();
    if (deviceInfo.platform === 'web') {
      this.screenHeight = window.innerHeight.toString() + 'px';
    } else {
      this.screenHeight = '100%'
    }
  }
  async ngOnInit() {
    await this.initStorage();
    setTimeout(() => {
      this.showSplash = false;
    }, 3000);
  }

  /**
   * Setup the default language of the app
   */
  setupLanguage(): void {
    this.translationService.init();
  }

  /**
   * Init storage
   */
  async initStorage(): Promise<void> {
    await this.storageService.initStorageSettings();
  }

  async initializeApp(): Promise<void> {
    const deviceInfo = await Device.getInfo();
    this.updateScreenHeight()
    this.platform.ready().then(async () => {
      /**
       * Before anything else, get the app's configuration to get data needed
       * for the functionality of the app such as app mode (patient type, AIs available).
       * ONLY IF THE CALL WAS SUCCESSFUL, hide the splash and continue the app's flow.
       * Otherwise, alert the user.
       */
      this.authService.fetchAppConfiguration().subscribe(async (res: any) => {
        if (res?.app) {
          this.dataService.setAppData(res.app);
          // Hide splash screen after 1 second (1000 milliseconds)
          setTimeout(() => {
            if (this.platform.is('capacitor')) {
              SplashScreen.hide();
            }
          }, 1000);
          if (deviceInfo.platform === 'ios' && deviceInfo.model.includes('iPhone')) {
            this.isIphone = true;
          }
          if (this.platform.is('capacitor')) {
            this.platform.backButton.subscribeWithPriority(-1, () => {
              if (!this.routerOutlet.canGoBack()) {
                App.exitApp();
              }
            });
          }
          this.configureApp(res);
          this.setFirstPage();
        }
      }, error => {
        console.log('error getting app config', error);
        this.presentToast('Error getting app config');
        this.router.navigate(['/public/login']);
      });
    });
  }

  /**
   * Save the different keys & values that the app might need to operate and perform
   * all flows ot functions. The data is obtained by calling on the
   * 'fetchAppConfiguration' service during app initialization.
   * TODO: Set an interface for the responbse object?
   */
  configureApp(fetchAppConfigResp: any): void {
    const appUserType: UserType = fetchAppConfigResp.app.patientsLoginType ? 'patient' : 'professional';
    const countries = fetchAppConfigResp.countries;
    const ccaas = fetchAppConfigResp.ccaas;
    const provinces = fetchAppConfigResp.provinces;
    const securityQuestions = fetchAppConfigResp.securityQuestions;
    const professionalAreas = fetchAppConfigResp.areas;
    const professionalPositions = fetchAppConfigResp.positions;
    const professionalProfiles = fetchAppConfigResp.professionalProfiles;
    // Save the ias in storage to access on future visits
    this.storageService.set(StorageKey.availableIAs, fetchAppConfigResp.app?.appIATypes ? fetchAppConfigResp.app.appIATypes : []);
    // The also save them in the dataService to prevent unneeded localstorage accesses further on
    this.dataService.setAvailableIAs(fetchAppConfigResp.app?.appIATypes);
    this.setUserType(appUserType);
    this.setAvailableLocations(countries, ccaas, provinces);
    this.setProfessionalOptions(professionalAreas, professionalPositions, professionalProfiles);
    this.setAvailableSecurityQuestions(securityQuestions);
  }

  setUserType(appMetaUserType: string): void {
    this.storageService.set(StorageKey.userType, appMetaUserType);
  }

  setAvailableLocations(countries: any[], CCAAs: any[], provinces: any[]): void {
    if (countries?.length && CCAAs?.length && provinces?.length) {
      this.dataService.setCountriesList(countries);
      this.dataService.setCCAAsList(CCAAs);
      this.dataService.setProvincesList(provinces);
    } else {
      this.showErrorAlert(
        this.translate.instant('COMMON.CONFIG_ERROR'),
        this.translate.instant('APP_INIT.GEO_DATA_ERROR'),
      );
    }
  }

  setProfessionalOptions(professionalAreas: any[], positions: any[], professionalProfiles: any[]) {
    if (positions?.length && professionalAreas?.length && professionalProfiles?.length) {
      this.storageService.set(StorageKey.areas, professionalAreas);
      this.storageService.set(StorageKey.positions, positions);
      this.storageService.set(StorageKey.professionalProfiles, professionalProfiles);
    }
  }

  setAvailableSecurityQuestions(securityQuestions: any[]): void {
    if (securityQuestions?.length) {
      this.storageService.set(StorageKey.availableSecurityQuestions, securityQuestions);
    } else {
      this.showErrorAlert(
        this.translate.instant('COMMON.CONFIG_ERROR'),
        this.translate.instant('APP_INIT.SECURITY_QUESTIONS_ERROR'),
      );
    }
  }

  async setFirstPage(): Promise<void> {
    // Depending on previous interactions, the first page might be one of three options.
    let isFirstAccess: boolean = await this.storageService.get(StorageKey.firstAccess);
    const userToken: boolean = await this.storageService.get(StorageKey.accessToken);
    const isTokenValid: boolean = await this.authService.checkTokenValidity();
    if (isFirstAccess === null) {
      isFirstAccess = true;
      this.authService.fetchAppConfiguration().subscribe(async (res: any) => {
        if (res?.app) {
          this.dataService.setAppData(res.app);
          // Hide splash screen after 1 second (1000 milliseconds)
          setTimeout(() => {
            if (this.platform.is('capacitor')) {
              SplashScreen.hide();
            }
          }, 1000);

          if (this.platform.is('capacitor')) {
            this.platform.backButton.subscribeWithPriority(-1, () => {
              if (!this.routerOutlet.canGoBack()) {
                App.exitApp();
              }
            });
          }
          this.configureApp(res);
        }
      }, error => {
        console.log('error getting app config', error);
        this.presentToast('Error getting app config');
        this.router.navigate(['/public/login']);
      });
    }
    if (userToken && isTokenValid) {
      // If userToken, the user already accepted all conditions and logged in
      await this.onPreviousLoginRedirect();
    } else if (userToken && !isTokenValid) {
      // There might be a userToken but not valid/expired
      this.router.navigate(['/public/login']);
    } else if (isFirstAccess && !userToken) {
      // If no userToken && firstAccess, we need to determine the type of user before going furthe
      this.router.navigate(['/public/onboarding']);
    } else if (!isFirstAccess && (!userToken || userToken && !isTokenValid)) {
      // If neither variable is true, there might be a case where the user accepted the iaGEMA terms but didn't complete the login
      this.router.navigate(['/public/login']);
    }
  }

  async onPreviousLoginRedirect() {
    /**
     * Per client request, if the user only has one available IA to use,
     * the user should be redirected directly to the chat page for that IA after login.
     */
    let availableIAs;
    // Check if the dataservice has them stored to prevent calling on localStorage
    const dataServiceIAs = this.dataService.getIAsList();
    if (dataServiceIAs.length > 0) {
      availableIAs = dataServiceIAs;
    } else {
      // if not, fetch them from local storage
      availableIAs = await this.storageService.get(StorageKey.availableIAs);
    }
    console.log('ias', availableIAs);
    // Finally, redirect to chat page ONLY if there is exactly one IA. If not, redirect to home
    if (availableIAs.length === 1) {
      this.chatService.setSelectedIA(availableIAs[0]);
      this.router.navigateByUrl(`/chat/${availableIAs[0].IATypeId}`, { state: { ia: availableIAs[0] } });
    } else {
      this.router.navigate(['/private/home']);
    }
  }

  async showErrorAlert(errorTitle: string, errorMessage: string): Promise<void> {
    const errorAlert = await this.alertCtrl.create({
      header: errorTitle,
      message: errorMessage,
      buttons: [
        {
          text: 'Ok',
          handler: () => { },
        },
      ],
    });
    await errorAlert.present();
  }

  async presentToast(message: string): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: message,
      duration: 5000,
    });
    toast.present();
  }

}
