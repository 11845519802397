import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

/**
 * Image Loader Component
 */
@Component({
  selector: 'app-image-loader',
  styleUrls: ['./image-loader.component.scss'],
  templateUrl: './image-loader.component.html',
})
export class ImageLoaderComponent {

  /**
   * Defines a string variable to manage the image url
   */
  @Input() set imageURL(value: { url: string; localFile?: boolean }) {
    if (!value.localFile) {
      this.url = `${environment.assetsBaseUrl}${value.url}`;
    } else {
      this.url = value.url;
    }
  }

  /**
   * Defines a number variable to manage the width
   */
  @Input() set width(value: number | string) {
    this.intWidth = `${value}`;
  }

  /**
   * Defines a number variable to manage the height
   */
  @Input() set height(value: number | string) {
    this.intHeight = `${value}`;
  }

  /**
   * Defines a number variable to manage the default height
   */
  @Input() set defaultHeight(value: string) {
    this.intHeight = value;
  }

  /**
   * Defines a string variable to manage the object-fit property of CSS
   */
  @Input() objectFit: string;

  /**
   * Defines a string variable to manage the default icon to show
   */
  @Input() defaultIcon: string;

  /**
   * Defines a string variable to manage the background color.
   *
   * Available options: 'primary' or 'gray'
   */
  @Input() backgroundColor: 'primary' | 'gray';

  /**
   * Defines a boolean variable to manage the load image status
   */
  loaded = false;

  /**
   * Defines a boolean variable to manage the load image status
   */
  loadError = false;

  /**
   * Defines a string variable to manage the width
   */
  intWidth = '100%';

  /**
   * Defines a string variable to manage the height
   */
  intHeight: string;

  /**
   * Defines a string variable to manage the url
   */
  url: string;

  /**
   * Called when the image is loaded
   */
  onLoaded(): void {
    this.loaded = true;
    this.loadError = false;
  }

  /**
   * Called when the image load fails
   */
  onError(): void {
    this.loaded = false;
    this.loadError = true;
  }
}
