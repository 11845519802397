/* eslint-disable max-len */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from 'src/app/core/interfaces/environment';

/**
 * DEV environment
 */
export const environment: Environment = {
  production: false,
  logStorageOnStartup: true,
  //apiBaseUrl: 'https://luzan5-api-int.armadilloamarillo.cloud/api',
  assetsBaseUrl: import.meta.env.NG_APP_API_ASSETS_URL,
  apiBaseUrl: import.meta.env.NG_APP_API_URL,
  dbName: '__App_Skel_DEV_DB',
  // eslint-disable-next-line max-len
  emailRegex: new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),
  passwordRegex: new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,30}$/),
  phoneRegex: new RegExp(/^\+?\d*$/),
  birthDateRegex: new RegExp(/^(0?[1-9]|[12][\d]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/),
  contactUrl: 'https://desk.zoho.com/support/fbw?formType=AdvancedWebForm&fbwId=edbsn1e55b30d5c82987ab4c5038fa06474f6ff90b1aaa50ff69a94a4441f39cf2b0a&xnQsjsdp=edbsnf5b147bf53dcdb6066ceff8796a7036f&mode=showNewWidget&displayType=iframe',
  //appId: '1234231f13f13f13f', // For the 'Patients' mode
  appId: 'jfk28939jfsj9dfj923', // For the 'Professionals' mode
  userType: 'professional',
  appName: 'iaGema',
  maxScreenWidth: 1100,
  sourcesUrl: "https://gemasma.com/"
};
