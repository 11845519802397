/* eslint-disable indent */
/* eslint-disable sort-imports */
/* eslint-disable object-shorthand */
import { ApiCallService } from 'src/app/services/api-call/api-call.service';
import { StorageService } from '../storage/storage.service';
import { Injectable } from '@angular/core';
import { StorageKey } from 'src/app/core/interfaces/storage';
import { DataCall } from '../api-call';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class SettingsService {

  /**
   * Constructor
   *
   * @param apiCallService
   * @param router
   * @param storageService
   */

  constructor(
    private readonly apiCallService: ApiCallService,
    private storage: StorageService,
  ) { }

  // Full list of countries available in the app
  async getSettings(): Promise<any> {
    const payload = {
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/getAppMeta',
      body: payload,
    };

    try {
      const result = await this.apiCallService.generateCall(dataCall).toPromise();
      return result;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  async getSplashImage(): Promise<string | null> {
    const payload = {
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/getAppMeta',
      body: payload,
    };

    try {
      const result = await this.apiCallService.generateCall(dataCall).toPromise();
      const imageUrl = environment.assetsBaseUrl + result.app.imgSplash;

      const imageResponse = await fetch(imageUrl);
      if (!imageResponse.ok) {
        throw new Error('Failed to fetch image from API');
      }
      this.saveImageBase64(imageResponse);
      return imageUrl;
    } catch (error) {
      console.log(error);
      // En caso de error al obtener la imagen de la API, intenta cargar la última imagen guardada en localStorage
      const lastSavedImage = localStorage.getItem('splashImageBase64');
      if (lastSavedImage) {
        // Decodificar la imagen base64 para obtener la URL de la imagen
        const imageUrl = `data:image/png;base64,${lastSavedImage}`;
        return imageUrl;
      }
      return null;
    }
  }
  async saveImageBase64(imageResponse: any) {
    const imageArrayBuffer = await imageResponse.arrayBuffer();
    const base64Image = btoa(
      new Uint8Array(imageArrayBuffer)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    localStorage.setItem('splashImageBase64', base64Image);

  }
  async getSponsorImage(): Promise<string | null> {
    const payload = {
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/getAppMeta',
      body: payload,
    };

    try {
      const result = await this.apiCallService.generateCall(dataCall).toPromise();
      const imageUrl = environment.assetsBaseUrl + result.app.imgIco;

      const imageResponse = await fetch(imageUrl);
      if (!imageResponse.ok) {
        throw new Error('Failed to fetch image from API');
      }
      const imageArrayBuffer = await imageResponse.arrayBuffer();

      // Convertir el array de bytes en una cadena en formato base64
      const base64Image = btoa(
        new Uint8Array(imageArrayBuffer)
          .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );

      // Guardar la imagen en base64 en localStorage
      localStorage.setItem('sponsorImageBase64', base64Image);


      return imageUrl;
    } catch (error) {
      console.log(error);
      // En caso de error al obtener la imagen de la API, intenta cargar la última imagen guardada en localStorage
      const lastSavedImage = localStorage.getItem('sponsorImageBase64');
      if (lastSavedImage) {
        // Decodificar la imagen base64 para obtener la URL de la imagen
        const imageUrl = `data:image/png;base64,${lastSavedImage}`;
        return imageUrl;
      }
      return null;
    }
  }



}
