import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { AppSkelStorage, StorageKey } from 'src/app/core/interfaces/storage';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';

/**
 * Storage Service
 *
 * Wrapper for Storage
 *
 * https://github.com/ionic-team/ionic-storage
 *
 */
@Injectable({
  providedIn: 'root',
// eslint-disable-next-line indent
})
export class StorageService {

  /**
   * Not clear this keys when logout
   */
  private readonly lockedKeys: StorageKey[] = [
    StorageKey.language,
    StorageKey.firstLogin,
    StorageKey.firstAccess,
  ];

  /**
   * Keys saved in storage
   */
  private readonly keys: StorageKey[] = [
    StorageKey.accessToken,
    StorageKey.language,
    StorageKey.userData,
    StorageKey.userType,
    StorageKey.firstLogin,
    StorageKey.firstAccess,
    StorageKey.userAcceptsBiometry,
    StorageKey.chosenBiometry,
    StorageKey.biometryModalShown,
    StorageKey.availableIAs,
    StorageKey.availableCountries,
    StorageKey.availableCCAAs,
    StorageKey.availableProvinces,
    StorageKey.availableSecurityQuestions,
    StorageKey.areas,
    StorageKey.positions,
    StorageKey.professionalProfiles,
  ];

  /**
   * Default values
   */
  private readonly defaultValues: AppSkelStorage = {
    [StorageKey.accessToken]: null,
    [StorageKey.language]: null,
    [StorageKey.userData]: null,
    [StorageKey.userType]: null,
    [StorageKey.firstLogin]: true,
    [StorageKey.firstAccess]: true,
    [StorageKey.userAcceptsBiometry]: null,
    [StorageKey.biometryModalShown]: null,
    [StorageKey.chosenBiometry]: null,
    [StorageKey.availableIAs]: null,
    [StorageKey.availableCountries]: null,
    [StorageKey.availableCCAAs]: null,
    [StorageKey.availableProvinces]: null,
    [StorageKey.availableSecurityQuestions]: null,
    [StorageKey.areas]: null,
    [StorageKey.positions]: null,
    [StorageKey.professionalProfiles]: null,
  };

  /**
   * Get the saved keys
   */
  private readonly readSavedKeys: any = this.storage.keys.bind(this.storage);

  /**
   * Constructor
   *
   * @param storage
   */
  constructor(
    private readonly storage: Storage,
  ) {}

  /**
   * Check if first pair key exists, if not, it creates all pairs with default values
   */
  async initStorageSettings(): Promise<void> {
    await this.storage.defineDriver(CordovaSQLiteDriver);

    await this.storage.create();
    const savedKeys = await this.readSavedKeys();
    const newKeys = this.keys.filter((key) => savedKeys.indexOf(key) === -1);
    if (newKeys.length === 0) {
      return;
    }

    console.log('Creating new storage Keys:', newKeys);
    await Promise.all(newKeys.map((key) => this.createRecord(key)));
  }

  /**
   * Create new record in storage
   *
   * @param key - The key to create
   */
  private async createRecord(key: StorageKey): Promise<void> {
    await this.storage.set(key, this.defaultValues[key]);
  }

  /**
   * Get the value associated with the given key
   *
   * @param key - The key to identify this value
   */
  async get(key: StorageKey): Promise<any> {
    return this.storage.get(key);
  }

  /**
   * Set the value for the given key
   *
   * @param key - The key to identify this value
   * @param value - The value for this key
   */
  async set(key: StorageKey, value: any): Promise<void> {
    return this.storage.set(key, value);
  }

  /**
   * Delete single key
   */
  async delete(key: StorageKey): Promise<any> {
    return this.storage.remove(key);
  }

  /**
   * Clear all data except 'FIRST_TIME'
   */
  async clearAll(): Promise<void> {
    const keys = await this.storage.keys();

    for (const key of keys) {
      // Include exceptions here for keys which should never be deleted
      if (key === StorageKey.firstLogin) {
        this.set(StorageKey.firstLogin, true);
      } else if (key === StorageKey.firstAccess) {
        this.set(StorageKey.firstAccess, false);
      } else {
        await this.storage.remove(key);
      }
    }

    await this.initStorageSettings();
  }

  /**
   * Clear only the unlocked keys
   */
  async clearUnlockedKeys(): Promise<any> {
    const keys = await this.storage.keys();

    for (const key of keys) {
      if (this.lockedKeys.indexOf(key as StorageKey) === -1) {
        await this.storage.remove(key);
      }
    }
    await this.initStorageSettings();
  }

}
