import { CommonModule } from '@angular/common';
import { ImageLoaderComponent } from './image-loader.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [ImageLoaderComponent],
  exports: [ImageLoaderComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
})
export class ImageLoaderComponentModule {}
