/* eslint-disable indent */
import { BackendError, BackendErrorCode } from 'src/app/core/interfaces/backend-error';
import { ModalController, NavParams } from '@ionic/angular';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Backend error Modal
 */
@Component({
  selector: 'app-backend-error',
  templateUrl: './backend-error.modal.html',
})
export class BackendErrorModal {

  /**
   * Defines a BackendError variable to manage the backend error
   */
  error: BackendError;

  /**
   * Constructor
   *
   * @param navParams
   * @param modalCtrl
   * @param translateService
   */
  constructor(
    private readonly navParams: NavParams,
    private readonly modalCtrl: ModalController,
    private readonly translateService: TranslateService,
  ) {}

  /**
   * Runs when the page is about to enter and become the active page.
   */
  async ionViewWillEnter(): Promise<void> {
    this.error = this.navParams.get('error');
  }

  /**
   * Dismiss modal
   */
  async dismiss(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  /**
   * Get translation with error_code field
   */
  getTranslation(): string {
    if (this.error?.error_code && this.hastTranslation(`BACKEND_ERRORS.${this.error?.error_code}`)) {
      return this.translateService.instant(`BACKEND_ERRORS.${this.error?.error_code}`);
    } else {
      return this.translateService.instant(`BACKEND_ERRORS.${BackendErrorCode.GENERIC}`);
    }
  }

  /**
   * Check if the translation exists
   *
   * @param key - Translation key
   */
  hastTranslation(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }
}
