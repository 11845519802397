/* eslint-disable sort-imports */
/* eslint-disable indent */
import { BackendErrorModal } from './backend-error/backend-error.modal';
import { BiometricModalComponent } from './biometric-modal/biometric-modal.component';
import { CommonModule } from '@angular/common';
import { CommonConfirmationModal } from './common-confirmation-modal/common-confirmation-modal';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { UseDisclaimerModal } from './use-disclaimer-modal/use-disclaimer-modal';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    CommonConfirmationModal,
    BackendErrorModal,
    UseDisclaimerModal,
    BiometricModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild(),
  ]
})
export class ModalsModule {}
