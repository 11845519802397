/* eslint-disable arrow-body-style */
/* eslint-disable indent */
import { StorageKey, UserType } from 'src/app/core/interfaces/storage';
import { ApiCallService } from 'src/app/services/api-call/api-call.service';
import { Credentials } from 'src/app/core/interfaces/credentials';
import { DataCall } from '../api-call';
import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { SecurityQuestions } from 'src/app/core/interfaces/security-question';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

/**
 * Auth Service
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private appData: any;
  public availableSecurityQuestions: Array<SecurityQuestions> = [];

  /**
   * Constructor
   *
   * @param apiCallService
   * @param navCtrl
   * @param storageService
   */
  constructor(
    private readonly apiCallService: ApiCallService,
    private readonly navCtrl: NavController,
    private readonly storageService: StorageService,
  ) { }

  fetchAppConfiguration(): Observable<any> {
    const payload = {
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/getAppMeta',
      body: payload,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      // eslint-disable-next-line arrow-body-style
      map((data: any) => {
        return data;
      }),
    );
  }

  setAppData(data: any) {
    this.appData = data;
  }

  getAppData() {
    console.log('should return', this.appData);
    return this.appData;
  }
  /**
   * Check if the access token exists
   */
  async hasAccessToken(): Promise<boolean> {
    return (await this.storageService.get(StorageKey.accessToken)) !== null;
  }

  /**
   * Validates if the email exists in the platform or not. Necessary so that the user
   * can be warned in the first or any step of a register process instead of having to wait
   * until the final step to receive a warning about this.
   *
   * @param registerEmail email provided by the user during register
   * @returns a boolean representing if the email already exists in the database
   */
  async isEmailRegistered(registerEmail: string): Promise<any> {
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/existsEmail',
      body: { email: registerEmail, appId: environment.appId },
    };
    try {
      const result = await this.apiCallService.generateCall(dataCall).toPromise();
      return result.exists;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   * Sign in. Common method for patients and professionals
   *
   * @param dataLogin - User credentials
   */
  signIn(dataLogin: Credentials, userType: UserType): Observable<any> {
    const dataLoginWithAppId = {
      ...dataLogin,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: userType === 'professional' ? '/auth/login' : '/auth/anonymousLogin',
      body: dataLoginWithAppId,
      storeName: 'userData',
    };

    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  /**
   * Request a password recover.Common for patients and professionals
   */
  requestPasswordRenewal(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/resetPasswordEmail',
      body: callBodyWithAppId,
      storeName: 'userData',
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  setAvailableSecurityQuestions(questions: SecurityQuestions[]) {
    this.availableSecurityQuestions = questions;
  }

  getAvailableSecurityQuestions() {
    return this.availableSecurityQuestions;
  }

  getUsernameSecurityQuestion(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/getUsernameSecurityQuestion',
      body: callBody,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  checkUsernameSecurityQuestion(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/checkUsernameSecurityQuestion',
      body: callBodyWithAppId,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  saveNewPassword(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/resetPasswordUsername',
      body: callBodyWithAppId,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return true;
      }),
    );
  }

  validateInvitationCode(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/validateInvitationCode',
      body: callBodyWithAppId,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  anonymousRegister(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/anonymousRegister',
      body: callBodyWithAppId,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return true;
      }),
    );
  }

  professionalRegister(callBody: any): Observable<any> {
    const callBodyWithAppId = {
      ...callBody,
      appId: environment.appId,
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/register',
      body: callBodyWithAppId,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return true;
      }),
    );
  }

  associateUpdatedLicense(userId: number, newLicenseCode: string, token: string) {
    const callBody = {
      userId: userId,
      code: newLicenseCode
    };
    const dataCall: DataCall = {
      type: 'post',
      path: '/user/updateLicense',
      body: callBody,
      accessToken: token,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  async checkTokenValidity(): Promise<boolean> {
    const accessToken: string = await this.storageService.get(StorageKey.accessToken);
    if (!accessToken) {
      return false;
    } else {
      const accessTokenExpiration = this.decodeToken(accessToken).exp * 1000;
      const nowTimeStamp = Date.now();
      return nowTimeStamp < accessTokenExpiration;
    }
  }

  refreshAuthToken(refreshToken: any): Observable<any> {
    const dataCall: DataCall = {
      type: 'post',
      path: '/auth/refreshToken',
      body: refreshToken,
    };
    return this.apiCallService.generateCall(dataCall).pipe(
      map((data: any) => {
        return data;
      }),
    );
  }

  /**
   * Logout
   */
  async logout() {
    this.apiCallService.clearApiStore();

    await this.storageService.clearAll();
    await this.navCtrl.navigateRoot('/public/login');
  }

  private decodeToken(token) {
    const decodedToken = (tokenToparse) => {
      try {
        return JSON.parse(atob(tokenToparse));
      } catch {
        return;
      }
    };
    return token
      .split('.')
      .map((mappedToken: any) => decodedToken(mappedToken))
      .reduce((acc, curr) => {
        // eslint-disable-next-line curly
        if (!!curr) acc = { ...acc, ...curr };
        return acc;
      }, Object.create(null));
  }
}
